import axios from 'axios';
import { MAIN_URL } from '../../utils/constants';
import { store } from '../index';
import { logOutRed, setUser } from '../Redux/loginReducer/action';
import { jwtDecode } from 'jwt-decode';
import showNotification from '../../utils/showNotification';
import { getI18n } from 'react-i18next';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

function isTokenExpiringSoon(token, thresholdMs = 5 * 60 * 1000) { // 5 minutes before expiry
  try {
    const decoded = jwtDecode(token);
    if (!decoded.exp) return false; // If no expiration, don't force refresh
    
    const expiresAt = decoded.exp * 1000;
    const now = Date.now();
    const timeUntilExpiry = expiresAt - now;
    
    if (process.env.NODE_ENV === 'development') {
      console.log('Token Check:', {
        expiresAt: new Date(expiresAt).toISOString(),
        currentTime: new Date(now).toISOString(),
        timeUntilExpiry: `${Math.floor(timeUntilExpiry / 1000)} seconds`,
        isExpiringSoon: timeUntilExpiry < thresholdMs
      });
    }
    
    return timeUntilExpiry < thresholdMs;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false; // On error, don't force refresh
  }
}

const handleSessionExpired = () => {
  showNotification({
    type: 'warning',
    content: getI18n().t('session_expired')
  });
  
  isAlreadyFetchingAccessToken = false;
  subscribers = [];
  
  setTimeout(() => {
    store.dispatch(logOutRed());
    window.location.replace('/login');
  }, 2000);
};

const onRequest = (config) => {
  const storageData = store.getState().loginReducer;

  if (process.env.NODE_ENV === 'development') {
    console.log('Request URL:', config.url);
    console.log('Base URL:', MAIN_URL);
  }

  if (storageData?.accessToken) {
    config.headers.Authorization = `Bearer ${storageData.accessToken}`;
    if (storageData.refreshToken) {
      config.headers['x-refresh-token'] = storageData.refreshToken;
    }
  }

  return config;
};

const refreshAccessToken = async (refreshToken) => {
  if (isAlreadyFetchingAccessToken) return;
  
  isAlreadyFetchingAccessToken = true;
  console.log('Attempting to refresh access token');
  
  try {
    const response = await axios.put(
      `${MAIN_URL}/v1/auth/refresh-tokens`,
      {},
      { 
        headers: { 
          'x-refresh-token': refreshToken 
        } 
      }
    );
    
    if (response?.data?.accessToken) {
      console.log('Token refresh successful');
      store.dispatch(setUser(response.data));
      onAccessTokenFetched(response.data.accessToken);
    }
  } catch (error) {
    console.error('Token refresh failed:', error.response?.status);
    handleSessionExpired();
  } finally {
    isAlreadyFetchingAccessToken = false;
  }
};

const onRequestError = (error) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response.data;
};

const onResponseError = async (error) => {
  const originalRequest = error.config;
  console.log('Response Error:', {
    status: error.response?.status,
    url: originalRequest?.url,
    isRetry: originalRequest?._retry
  });

  if (error.response?.status === 403 && !originalRequest._retry) {
    console.log('403 Forbidden - Attempting token refresh');
    originalRequest._retry = true;
    const storageData = store.getState().loginReducer;
    
    if (!storageData?.refreshToken) {
      console.log('No refresh token available');
      handleSessionExpired();
      return Promise.reject(error);
    }

    try {
      await refreshAccessToken(storageData.refreshToken);
      return axios(originalRequest);
    } catch (refreshError) {
      console.error('Refresh attempt failed');
      handleSessionExpired();
      return Promise.reject(refreshError);
    }
  }

  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export default setupInterceptorsTo;

import packageJson from '../../package.json';

// Add debug logging
const getVersion = () => {
  const envVersion = process.env.REACT_APP_VERSION;
  const pkgVersion = packageJson.version;
  
  if (process.env.NODE_ENV === 'development') {
    console.log('Environment Version:', envVersion);
    console.log('Package Version:', pkgVersion);
  }
  
  return pkgVersion; // Directly use package.json version since we know it exists
};

export const APP_VERSION = getVersion();

export const getStoredVersion = () => {
  const stored = localStorage.getItem('app_version');
  if (process.env.NODE_ENV === 'development') {
    console.log('Stored Version:', stored);
  }
  return stored;
};

export const setStoredVersion = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Setting Version:', APP_VERSION);
  }
  localStorage.setItem('app_version', APP_VERSION);
};

export const shouldClearStorage = () => {
  const storedVersion = getStoredVersion();
  console.log('Comparing Versions:', { stored: storedVersion, current: APP_VERSION });
  
  if (!storedVersion || storedVersion !== APP_VERSION) {
    setStoredVersion();
    return true;
  }
  return false;
};

export const isNewVersion = () => {
  const storedVersion = getStoredVersion();
  if (!storedVersion) {
    setStoredVersion();
    return true;
  }
  return storedVersion !== APP_VERSION;
};

export const parseVersion = (version) => {
  const [major, minor, patch] = version.split('.').map(Number);
  return { major, minor, patch };
};

export const compareVersions = (v1, v2) => {
  const ver1 = parseVersion(v1);
  const ver2 = parseVersion(v2);

  if (ver1.major !== ver2.major) return ver1.major - ver2.major;
  if (ver1.minor !== ver2.minor) return ver1.minor - ver2.minor;
  return ver1.patch - ver2.patch;
}; 
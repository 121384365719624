import { jwtDecode } from 'jwt-decode';
import { store } from '../services';

export const logTokenInfo = () => {
  const state = store.getState().loginReducer;
  const { accessToken, refreshToken } = state;

  console.group('Token Debug Info');
  console.log('Current Time:', new Date().toISOString());
  
  if (accessToken) {
    try {
      const decodedAccess = jwtDecode(accessToken);
      console.log('Access Token:', {
        token: `${accessToken.substring(0, 20)}...${accessToken.substring(accessToken.length - 20)}`,
        expiresAt: new Date(decodedAccess.exp * 1000).toISOString(),
        timeUntilExpiry: Math.floor((decodedAccess.exp * 1000 - Date.now()) / 1000) + ' seconds',
        decoded: decodedAccess
      });
    } catch (e) {
      console.log('Access Token: Invalid or expired');
    }
  } else {
    console.log('Access Token: Not present');
  }

  if (refreshToken) {
    // The refresh token is a base64 string, not a JWT
    console.log('Refresh Token:', {
      present: true,
      token: refreshToken.substring(0, 20) + '...',
      // Backend sets expiry to 12 minutes from creation
      note: 'Refresh token is a base64 string with 12-minute server-side expiry'
    });
  } else {
    console.log('Refresh Token: Not present');
  }

  console.groupEnd();
};

// Make it available globally in development
if (process.env.NODE_ENV === 'development') {
  window.logTokenInfo = logTokenInfo;
} 
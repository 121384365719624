import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../services/Redux/reportingListReducer/action';
import dayjs from 'dayjs';

const useInitializeFilters = () => {
  const dispatch = useDispatch();
  const { selectedMonth, selectedYear } = useSelector(state => state.reportingListReducer.filters);

  useEffect(() => {
    if (!selectedMonth?.number || !selectedYear) {
      const today = dayjs();
      dispatch(setFilters({
        selectedMonth: {
          name: today.format('MMM'),
          number: today.month() + 1
        },
        selectedYear: today.year(),
        order: 'NOT_REPORTED'
      }));
    }
  }, []);
};

export default useInitializeFilters;

import React, { useMemo, useCallback } from 'react';
import { months } from '../../utils/constants';
import './styles.scss';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../services/Redux/reportingListReducer/action';

function MonthsComponent({ selectedMonth, selectedYear }) {
  const dispatch = useDispatch();

  const disabledMonths = useMemo(() => {
    const today = dayjs();
    return months().reduce((acc, _, index) => {
      acc[index] = selectedYear > today.year() || 
        (selectedYear === today.year() && index > today.month());
      return acc;
    }, {});
  }, [selectedYear]);

  const handleMonthChange = useCallback((month) => {
    if (!disabledMonths[parseInt(month.number, 10) - 1]) {
      dispatch(setFilters({ 
        selectedMonth: {
          name: month.name,
          number: parseInt(month.number, 10)
        },
        selectedYear
      }));
    }
  }, [disabledMonths, dispatch, selectedYear]);

  const isMonthSelected = useCallback((monthItem) => {
    if (!selectedMonth?.number) return false;
    return parseInt(monthItem.number, 10) === parseInt(selectedMonth.number, 10);
  }, [selectedMonth]);

  return (
    <div className='listMonths'>
      {months().map((item, index) => (
        <button
          key={index}
          onClick={() => handleMonthChange(item)}
          disabled={disabledMonths[index]}
          className={`listBorderlessButton ${
            isMonthSelected(item) ? 'selected' : ''
          } ${disabledMonths[index] ? 'future' : ''}`}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
}

export default React.memo(MonthsComponent);

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './styles.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../services/Redux/reportingListReducer/action';
import dayjs from 'dayjs';

const YearPicker = () => {
  const dispatch = useDispatch();
  const currentYear = dayjs().year();
  const [offset, setOffset] = useState(0);
  const containerRef = useRef(null);
  
  const selectedYear = useSelector(state => state.reportingListReducer.filters.selectedYear);
  const selectedMonth = useSelector(state => state.reportingListReducer.filters.selectedMonth);

  const VISIBLE_YEARS = 6;
  const TOTAL_YEARS = 21;
  const ANIMATION_DURATION = 500;
  const YEAR_WIDTH = 56;

  const allYears = Array.from(
    { length: TOTAL_YEARS }, 
    (_, i) => currentYear - 20 + i
  );

  useEffect(() => {
    const selectedIndex = allYears.indexOf(selectedYear || currentYear);
    setOffset(Math.max(0, Math.min(selectedIndex - 2, TOTAL_YEARS - VISIBLE_YEARS)));
  }, [selectedYear]); // Only react to selectedYear changes

  const handleChange = useCallback((year) => {
    if (year <= currentYear) {
      const start = dayjs(`${year}-${selectedMonth.number}-01`).startOf('month');
      const end = dayjs(`${year}-${selectedMonth.number}-01`).endOf('month');
      dispatch(setFilters({ 
        selectedYear: year, 
        selectedDates: [start, end] 
      }));
    }
  }, [dispatch, currentYear, selectedMonth]);

  const handleScroll = (direction) => {
    const newOffset = direction === 'left'
      ? Math.max(offset - VISIBLE_YEARS, 0)
      : Math.min(offset + VISIBLE_YEARS, TOTAL_YEARS - VISIBLE_YEARS);

    if (containerRef.current) {
      containerRef.current.style.transition = `transform ${ANIMATION_DURATION}ms cubic-bezier(0.25, 0.1, 0.25, 1)`;
      containerRef.current.style.transform = `translateX(${-newOffset * YEAR_WIDTH}px)`;
      
      setTimeout(() => setOffset(newOffset), ANIMATION_DURATION);
    }
  };

  return (
    <div className="yearSelector">
      <button
        className="yearButton yearButton-left"
        onClick={() => handleScroll('left')}
        disabled={offset === 0}
      >
        <LeftOutlined />
      </button>
      <div className="yearsContainerWrapper">
        <div 
          className="yearsContainer" 
          ref={containerRef}
          style={{ transform: `translateX(${-offset * YEAR_WIDTH}px)` }}
        >
          {allYears.map((year) => (
            <button
              key={year}
              className={`yearOption ${selectedYear === year ? 'selected' : ''}`}
              onClick={() => handleChange(year)}
              disabled={year > currentYear}
            >
              {year}
            </button>
          ))}
        </div>
      </div>
      <button
        className="yearButton yearButton-right"
        onClick={() => handleScroll('right')}
        disabled={offset >= TOTAL_YEARS - VISIBLE_YEARS}
      >
        <RightOutlined />
      </button>
    </div>
  );
};

export default YearPicker;

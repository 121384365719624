import { ActionTypes } from './actionsTypes';

export const getReportingList = (payload) => ({
  type: ActionTypes.GET_REPORTING_LIST,
  payload: {
    ...payload,
    limit: Math.min(payload.limit || 400, 400), // Ensure limit is not greater than 400
    offset: payload.offset || 0
  },
});

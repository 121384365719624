import { call, put, takeLatest } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import { setReportingList } from '../../Redux/reportingListReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';
import dayjs from 'dayjs';

export function* watcherGetReportingList() {
  yield takeLatest(ActionTypes.GET_REPORTING_LIST, workerGetReportingList);
}

function* workerGetReportingList({ payload }) {
  try {
    if (!payload?.date?.length === 2) return;

    const formatDate = date => 
      dayjs.isDayjs(date) ? date.format('YYYY-MM-DD') : dayjs(date).format('YYYY-MM-DD');

    const queryParams = new URLSearchParams({
      from: formatDate(payload.date[0]),
      to: formatDate(payload.date[1]),
      includeIsActive: 'true',
      order: payload.order || 'NOT_REPORTED',
      limit: String(Math.min(payload.limit || 400, 400)),
      offset: String(payload.offset || 0)
    });

    if (payload?.mallId) {
      queryParams.append('mallIds', payload.mallId);
    }

    const res = yield call(
      axiosInstance.get,
      `${MAIN_URL}/v1/stores/incomes/reports?${queryParams.toString()}`
    );

    yield put(setReportingList({
      stores: Array.isArray(res.data) ? res.data : [],
      total: Array.isArray(res.data) ? res.data.length : 0
    }));
  } catch (error) {
    yield put(setReportingList({ stores: [], total: 0 }));
  }
}

import { ActionTypes } from './actionTypes';

export const setReportingList = (payload) => ({
  type: ActionTypes.SET_REPORTING_LIST,
  payload,
});

export const setFilters = (payload) => ({
  type: ActionTypes.SET_FILTERS,
  payload,
});

export const setShowInactiveStores = (showInactiveStores) => ({
  type: ActionTypes.SET_SHOW_INACTIVE_STORES,
  payload: showInactiveStores,
});


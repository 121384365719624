import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Header } from '../../components/Header/Header';
import { LeftSideMenu } from '../../components/LeftMenu/LeftMenu';
import { Colors } from '../../utils/colors';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getReportingList } from '../../services/Saga/getReportingList/actions';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import MonthsComponent from '../../components/MonthsComponent';
import YearPicker from '../../components/YearPicker';
import { getAllMalls } from '../../services/Saga/filters/actions';
import {
  setFilters,
  setReportingList,
  setShowInactiveStores,
} from '../../services/Redux/reportingListReducer/action';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes/route';
import MallComponent from '../../components/MallComponent';
import StoresGrid from './components/StoresGrid';
import RightSideMenu from './components/RightSideMenu';
import useInitializeFilters from '../../utils/hooks/useInitializeFilters';
import { getDateRange } from '../../services/Redux/reportingListReducer/index';

export const ReportingList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Use the hook to initialize filters
  useInitializeFilters();

  const reportingList = useSelector((state) => state.reportingListReducer.reportingList);
  const allMalls = useSelector((state) => state.filtersReducer.allMalls);
  const filters = useSelector((state) => state.reportingListReducer.filters);
  const showInactiveStores = useSelector((state) => state.reportingListReducer.showInactiveStores);

  const [isLoading, setIsLoading] = useState(false);
  const [isRightMenuVisible, setIsRightMenuVisible] = useState(false); // Changed to false

  const {
    selectedMonth,
    selectedYear,
    selectedMallId: mallId,
    order,
  } = filters;

  const selectedMall = useMemo(() => 
    allMalls.data?.find(mall => mall.id === mallId) || null,
  [allMalls.data, mallId]);

  const fetchReportingList = useCallback(() => {
    const [start, end] = getDateRange(selectedMonth, selectedYear);
    if (!start || !end) return;
    
    setIsLoading(true);
    dispatch(setReportingList({ stores: [], total: 0 }));
    dispatch(getReportingList({
      date: [start, end],
      mallId,
      order,
      limit: 400,
      offset: 0
    }));
  }, [dispatch, selectedMonth, selectedYear, mallId, order]);

  useEffect(() => {
    fetchReportingList();
  }, [fetchReportingList]);

  useEffect(() => {
    if (reportingList.length > 0) {
      setIsLoading(false);
    }
  }, [reportingList]);

  useEffect(() => {
    if (allMalls?.data?.length > 0 && !mallId) {
      dispatch(setFilters({ selectedMallId: allMalls.data[0].id, order: 'NOT_REPORTED' }));
    }
  }, [allMalls, mallId, dispatch]);

  useEffect(() => {
    dispatch(getAllMalls({ order: 'ASC' }));
  }, [dispatch]);

  useEffect(() => {
    const currentDate = dayjs();
    
    // Initialize both month and year together
    if (!selectedMonth || !selectedYear) {
      console.log('Initializing filters on mount:', {
        currentDate: currentDate.format('YYYY-MM-DD'),
        hasMonth: !!selectedMonth,
        hasYear: !!selectedYear
      });

      const currentMonth = {
        name: currentDate.format('MMM'),
        number: currentDate.month() + 1
      };

      dispatch(setFilters({ 
        selectedMonth: currentMonth,
        selectedYear: currentDate.year(),
        order: 'NOT_REPORTED'
      }));
    }
  }, [selectedMonth, selectedYear, dispatch]);

  const handleDateChange = useCallback((year) => {
    dispatch(setFilters({ selectedYear: year }));
  }, [dispatch]);

  const navigateToIncome = useCallback((store) => {
    console.log('Store Click - Before Navigation:', {
      page: 'ReportingList',
      selectedMonth,
      selectedYear,
      store: store.id,
      timestamp: new Date().toISOString()
    });

    const [start, end] = getDateRange(selectedMonth, selectedYear);
    navigate(ROUTES.REPORT_INCOME, {
      state: {
        store: {
          id: store.id,
          name: store.name,
          mallName: store.mall?.name,
          tax: store.tax,
          startDate: store.startDate,
          endDate: store.endDate,
        },
        selected: 'INCOME',
        date: [
          { $d: start.toDate() },
          { $d: end.toDate() }
        ],
      },
    });
  }, [navigate, selectedMonth, selectedYear]);

  const handleMallChange = (mall) => {
    dispatch(setFilters({ selectedMallId: mall.id }));
  };

  const loadMore = useCallback(() => {
    // Implement loadMore functionality if needed
  }, []);

  const handleToggleInactiveStores = useCallback(() => {
    dispatch(setShowInactiveStores(!showInactiveStores));
  }, [dispatch, showInactiveStores]);

  const handleSortChange = useCallback((newOrder) => {
    dispatch(setFilters({ order: newOrder }));
  }, [dispatch]);

  // Fjern currentDate beregning fra render og flytt den til en useMemo
  const currentDate = useMemo(() => {
    if (!selectedMonth || !selectedYear) return null;
    const [start] = getDateRange(selectedMonth, selectedYear);
    return start;
  }, [selectedMonth, selectedYear]);

  return (
    <div className="reporting-list-pc">
      <Header />
      <div className='mainContainer' style={{ backgroundColor: Colors.mainBlack }}>
        <LeftSideMenu selected='LIST' />
        <div className={`contentContainer ${isRightMenuVisible ? 'menu-visible' : 'menu-hidden'}`} style={{ backgroundColor: Colors.mainBlack }}>
          <div className='listSortingContainer'>
            {selectedMall && allMalls.data && (
              <MallComponent
                selectedMall={selectedMall}
                setSelectedMall={handleMallChange}
                malls={allMalls.data}
              />
            )}
            <YearPicker
              selectedYear={selectedYear}
              onChange={handleDateChange}
            />
            <MonthsComponent
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
            />
          </div>
          <div className='scrollableContent'>
            <div className='listWrapper'>
              <StoresGrid 
                stores={reportingList}
                onStoreClick={navigateToIncome}
                loadMore={loadMore}
                isLoading={isLoading}
                showInactiveStores={showInactiveStores}
                currentDate={currentDate}
                filters={filters}
                onSortChange={handleSortChange}
              />
            </div>
          </div>
        </div>
        <RightSideMenu 
          onToggleInactiveStores={handleToggleInactiveStores}
          isRightMenuVisible={isRightMenuVisible}
          setIsRightMenuVisible={setIsRightMenuVisible}
          filters={filters}
          onSortChange={handleSortChange}
        />
      </div>
    </div>
  );
};

/**
 * Redux reducer for handling reporting list state
 * 
 * Key concepts:
 * 1. State Management:
 *    - getInitialState: Provides default values for first-time initialization
 *    - getLastState: Maintains persistent values between sessions/refreshes
 * 
 * 2. Actions:
 *    - SET_FILTERS: Primary method for updating filters. Always maintains state consistency
 *      and ensures selected values are properly synchronized. Used by all components to
 *      update filter values (month, year, mall, etc.)
 *    - INITIALIZE_FILTERS: Only used for daily reinitialization check. Should not be used
 *      for regular filter updates.
 * 
 * 3. Filter Updates:
 *    - All filter updates should use SET_FILTERS action
 *    - When setting selectedYear, selectedMonth should be validated
 *    - When setting selectedMonth, it should update visual selection state
 */

import dayjs from 'dayjs';
import { ActionTypes } from './actionTypes';

const getInitialState = () => {
  const today = dayjs();
  return {
    reportingList: [],
    total: 0,
    filters: {
      selectedMallId: null,
      selectedMonth: {
        name: today.format('MMM'),
        number: today.month() + 1,
      },
      selectedYear: today.year(),
      order: 'NOT_REPORTED',
    },
    showInactiveStores: true,
  };
};

export const getDateRange = (month, year) => {
  if (!month || !year) return [null, null];
  const start = dayjs(`${year}-${month.number}-01`).startOf('month');
  const end = dayjs(`${year}-${month.number}-01`).endOf('month');
  return [start, end];
};

export const reportingListReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.SET_REPORTING_LIST:
      return {
        ...state,
        reportingList: action.payload.stores || [],
        total: action.payload.total || 0,
      };
      
    case ActionTypes.SET_FILTERS: {
      const newFilters = { ...state.filters };
      const today = dayjs();
      
      if (action.payload.selectedYear !== undefined) {
        newFilters.selectedYear = action.payload.selectedYear;
        
        if (newFilters.selectedYear === today.year() && 
            newFilters.selectedMonth?.number > (today.month() + 1)) {
          newFilters.selectedMonth = {
            name: today.format('MMM'),
            number: today.month() + 1
          };
        }
      }

      if (action.payload.selectedMonth !== undefined) {
        newFilters.selectedMonth = {
          name: action.payload.selectedMonth.name,
          number: parseInt(action.payload.selectedMonth.number, 10)
        };
      }

      Object.keys(action.payload).forEach(key => {
        if (key !== 'selectedYear' && key !== 'selectedMonth') {
          newFilters[key] = action.payload[key];
        }
      });

      return {
        ...state,
        filters: newFilters
      };
    }

    case ActionTypes.SET_SHOW_INACTIVE_STORES:
      return {
        ...state,
        showInactiveStores: action.payload,
      };
      
    default:
      return state;
  }
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { setFilters } from '../../../../services/Redux/reportingListReducer/action';
import { getReportingList } from '../../../../services/Saga/getReportingList/actions';
import { Switch } from 'antd';
import './styles.scss';

const RightSideMenu = ({ children, onToggleInactiveStores, isRightMenuVisible, setIsRightMenuVisible, filters, onSortChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allMalls = useSelector((state) => state.filtersReducer.allMalls);
  const showInactiveStores = useSelector((state) => state.reportingListReducer.showInactiveStores);

  const mallsOptions = (allMalls.data || []).map((mall) => ({
    value: mall.id,
    label: mall.name,
  }));

  const formatDisplayText = (text) => {
    return text.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleStoreSortingOrderChange = (newOrder) => {
    onSortChange(newOrder);
  };

  return (
    <>
      <button 
        className="right-side-menu__toggle-button" 
        onClick={() => setIsRightMenuVisible(!isRightMenuVisible)}
        style={{ right: isRightMenuVisible ? '320px' : '0' }}
      >
        <span className="button-text">{t('options')}</span>
        {isRightMenuVisible ? <RightOutlined /> : <LeftOutlined />}
      </button>
      <div className={`right-side-menu ${isRightMenuVisible ? '' : 'hidden'}`}>
        <div className="section-title">
          <h3>{t('malls')}</h3>
        </div>
        <div className="malls-list">
          <ul>
            {mallsOptions.map((mall) => (
              <li 
                key={mall.value}
                className={mall.value === filters.selectedMallId ? 'selected' : ''}
                onClick={() => dispatch(setFilters({ selectedMallId: mall.value }))}
              >
                {mall.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="section-title">
          <h3>{t('sorting')}</h3>
        </div>
        <div className="sorting-list">
          <ul>
            <li 
              className={filters.order === 'REPORTED' ? 'selected' : ''}
              onClick={() => handleStoreSortingOrderChange('REPORTED')}
            >
              {t('reported')}
            </li>
            <li 
              className={filters.order === 'NOT_REPORTED' ? 'selected' : ''}
              onClick={() => handleStoreSortingOrderChange('NOT_REPORTED')}
            >
              {t('not_Reported')}
            </li>
          </ul>
        </div>
        <div className="section-title">
          <h3>{t('variables')}</h3>
        </div>
        <div className="variables">
        </div>
        <div className="section-title">
          <h3>{t('Display Options')}</h3>
        </div>
        <div className="display-options">
          <div className="option">
            <span>{formatDisplayText(t('Inactive_stores'))}</span>
            <Switch
              checked={showInactiveStores}
              onChange={onToggleInactiveStores}
            />
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default RightSideMenu;

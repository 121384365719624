import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import './style.scss';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const formatDate = (date) => {
  return date ? dayjs(date).format('DD.MM.YYYY') : '';
};

const StoreList = ({ stores = [], onStoreClick, title, renderStoreStatus, onSort, sortOrder }) => {
  const { t } = useTranslation();

  return (
    <div className="pc-stores-grid">
      <div className="pc-stores-list">
        <div className="pc-stores-list-header">
          <span>{title}</span>
          <span className="sortable" onClick={() => onSort()}>
            Status
          </span>
          <span>Start Date</span>
          <span>End Date</span>
        </div>
        {stores.map((store) => (
          <div
            key={store.id}
            className={`pc-store-option ${store.isReported ? 'reported' : 'not-reported'}`}
            onClick={() => onStoreClick(store)}
          >
            <span className="pc-store-option-name">{store.name}</span>
            {renderStoreStatus(store)}
            <span className="pc-store-option-value">{formatDate(store.startDate)}</span>
            <span className="pc-store-option-value">{formatDate(store.endDate)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const StoresGrid = React.memo(({ 
  stores = [], 
  onStoreClick, 
  loadMore, 
  isLoading, 
  showInactiveStores, 
  currentDate,
  filters,
  onSortChange
}) => {
  const { t } = useTranslation();

  const renderStoreStatus = useCallback((store) => (
    <span className="pc-store-option-value">
      {store.isActive
        ? store.isReported
          ? t('Reported')
          : t('Not Reported')
        : store.isReported
          ? t('last_reported', { date: dayjs(store.lastReportedDate).format('DD.MM.YYYY') })
          : t('No Reports')}
    </span>
  ), [t]);

  const sortedStores = useMemo(() => {
    const filteredStores = showInactiveStores 
      ? stores.filter(store => !store.isActive)
      : stores.filter(store => store.isActive);
    
    return filteredStores.sort((a, b) => {
      if (a.isReported === b.isReported) return 0;
      if (filters.order === 'REPORTED') {
        return a.isReported ? -1 : 1;
      } else {
        return a.isReported ? 1 : -1;
      }
    });
  }, [stores, showInactiveStores, filters.order]);

  const handleSort = useCallback(() => {
    const newOrder = filters.order === 'REPORTED' ? 'NOT_REPORTED' : 'REPORTED';
    onSortChange(newOrder);
  }, [filters.order, onSortChange]);

  return (
    <div className="pc-stores-grid-container">
      <StoreList 
        stores={sortedStores} 
        onStoreClick={onStoreClick} 
        title={showInactiveStores ? t("Inactive Stores") : t("Active Stores")} 
        renderStoreStatus={renderStoreStatus}
        onSort={handleSort}
        sortOrder={filters.order}
      />
    </div>
  );
});

StoresGrid.propTypes = {
  stores: PropTypes.array,
  onStoreClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showInactiveStores: PropTypes.bool.isRequired,
  currentDate: PropTypes.object.isRequired, // dayjs object
  filters: PropTypes.object.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default StoresGrid;

import { shouldClearStorage } from '../utils/version';
import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import rootReducer from './Redux';
import rootSaga from './Saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const configureStore = () => {
  // Check version and clear storage if needed
  if (shouldClearStorage()) {
    localStorage.clear();
  }

  const store = createStore(rootReducer, applyMiddleware(...middleware));
  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = configureStore();
export const dispatch = store.dispatch;
